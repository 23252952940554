import * as allIcons from '@ant-design/icons';
import { MenuDataItem } from '@ant-design/pro-layout';
import React from 'react';


/**
 * 首字母大写
 * @param inputString
 */
function capitalizeWords(inputString: string) {
  // 将字符串拆分为单词数组
  let words = inputString.split('-');
  // 对每个单词执行首字母大写操作
  let capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join('');
}

// FIX从接口获取菜单时icon为string类型
const fixMenuItemIcon = (menus: MenuDataItem[], iconType = 'Outlined'): MenuDataItem[] => {
  menus.forEach((item) => {
    const { icon, children } = item;
    if (typeof icon === 'string') {
      let fixIconName = capitalizeWords(icon) + iconType;
      item.icon = React.createElement(allIcons[fixIconName] || allIcons[icon]);
    }
  });
  return menus;
};
export default fixMenuItemIcon;
