export default {
  'pages.layouts.userLayout.title': 'Ant Design 是西湖區最具影響力的 Web 設計規範',
  'pages.login.accountLogin.tab': '賬戶密碼登錄',
  'pages.login.accountLogin.errorMessage': '錯誤的用戶名和密碼(admin/blue.shield)',
  'pages.login.failure': '登錄失敗，請重試！',
  'pages.login.success': '登錄成功！',
  'pages.login.username.placeholder': '用戶名: admin or user',
  'pages.login.username.required': '用戶名是必填項！',
  'pages.login.password.placeholder': '密碼',
  'pages.login.password.required': '密碼是必填項！',
  'pages.login.phoneLogin.tab': '手機號登錄',
  'pages.login.phoneLogin.errorMessage': '驗證碼錯誤',
  'pages.login.phoneNumber.placeholder': '請輸入手機號！',
  'pages.login.phoneNumber.required': '手機號是必填項！',
  'pages.login.phoneNumber.invalid': '不合法的手機號！',
  'pages.login.captcha.placeholder': '請輸入驗證碼！',
  'pages.login.captcha.required': '驗證碼是必填項！',
  'pages.login.phoneLogin.getVerificationCode': '獲取驗證碼',
  'pages.getCaptchaSecondText': '秒後重新獲取',
  'pages.login.rememberMe': '自動登錄',
  'pages.login.forgotPassword': '忘記密碼 ?',
  'pages.login.submit': '登錄',
  'pages.login.loginWith': '其他登錄方式 :',
  'pages.login.registerAccount': '註冊賬戶',
  'pages.welcome.link': '歡迎使用',
  'pages.welcome.alertMessage': '更快更強的重型組件，已經發布。',
  'pages.404.subTitle': '抱歉，您訪問的頁面不存在。',
  'pages.404.buttonText': '返回首頁',
  'pages.admin.subPage.title': '這個頁面只有 admin 權限才能查看',
  'pages.admin.subPage.alertMessage': 'umi ui 現已發佈，歡迎使用 npm run ui 啓動體驗。',
  'pages.searchTable.createForm.newRule': '新建規則',
  'pages.searchTable.updateForm.ruleConfig': '規則配置',
  'pages.searchTable.updateForm.basicConfig': '基本信息',
  'pages.searchTable.updateForm.ruleName.nameLabel': '規則名稱',
  'pages.searchTable.updateForm.ruleName.nameRules': '請輸入規則名稱！',
  'pages.searchTable.updateForm.ruleDesc.descLabel': '規則描述',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': '請輸入至少五個字符',
  'pages.searchTable.updateForm.ruleDesc.descRules': '請輸入至少五個字符的規則描述！',
  'pages.searchTable.updateForm.ruleProps.title': '配置規則屬性',
  'pages.searchTable.updateForm.object': '監控對象',
  'pages.searchTable.updateForm.ruleProps.templateLabel': '規則模板',
  'pages.searchTable.updateForm.ruleProps.typeLabel': '規則類型',
  'pages.searchTable.updateForm.schedulingPeriod.title': '設定調度週期',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': '開始時間',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': '請選擇開始時間！',
  'pages.searchTable.titleDesc': '描述',
  'pages.searchTable.ruleName': '規則名稱爲必填項',
  'pages.searchTable.titleCallNo': '服務調用次數',
  'pages.searchTable.titleStatus': '狀態',
  'pages.searchTable.nameStatus.default': '關閉',
  'pages.searchTable.nameStatus.running': '運行中',
  'pages.searchTable.nameStatus.online': '已上線',
  'pages.searchTable.nameStatus.abnormal': '異常',
  'pages.searchTable.titleUpdatedAt': '上次調度時間',
  'pages.searchTable.exception': '請輸入異常原因！',
  'pages.searchTable.titleOption': '操作',
  'pages.searchTable.config': '配置',
  'pages.searchTable.subscribeAlert': '訂閱警報',
  'pages.searchTable.title': '查詢表格',
  'pages.searchTable.new': '新建',
  'pages.searchTable.chosen': '已選擇',
  'pages.searchTable.item': '項',
  'pages.searchTable.totalServiceCalls': '服務調用次數總計',
  'pages.searchTable.tenThousand': '萬',
  'pages.searchTable.batchDeletion': '批量刪除',
  'pages.searchTable.batchApproval': '批量審批',
};
